import React from 'react'
import './style.css'

import { SlSocialInstagram, SlSocialFacebook, SlSocialTwitter } from 'react-icons/sl'

import logo from '../../assets/logo.svg'

export default function Footer () {
  return (
    <footer className="footer-page">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={logo} alt="Sua Logo" />
          </div>
          <div className="social-icons">
            <a href="https://www.instagram.com/acessaaidelivery" target="_blank" rel="noopener noreferrer">
              <SlSocialFacebook size={20}/>
            </a>
            <a href="https://www.instagram.com/acessaaidelivery" target="_blank" rel="noopener noreferrer">
              <SlSocialTwitter size={20}/>
            </a>
            <a href="https://www.instagram.com/acessaaidelivery" target="_blank" rel="noopener noreferrer">
              <SlSocialInstagram size={20}/>
            </a>
          </div>
        </div>
        <p>&copy; 2023 Acessa Ai. Todos os direitos reservados.</p>
      </div>
    </footer>
  )
}
