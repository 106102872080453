import { Switch, Route } from 'react-router-dom'
// import Route from './Route'

import NewOrder from '../pages/NewOrder'
import NotFound from '../pages/NotFound'
import LandingPage from '../pages/LandinPage'

export default function Routes () {
  return (
        <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/:id" component={NewOrder} />
            <Route component={NotFound} />
        </Switch>
  )
}
