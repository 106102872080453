import './style.css'
import { useContext, useEffect, useState } from 'react'
import firebase from '../../firebaseConect'
import { Redirect, useParams } from 'react-router-dom'
import { AuthContext } from '../../contexts/auth'

import { toast } from 'react-toastify'
import { MdDeliveryDining, MdMore } from 'react-icons/md'
import { FiArrowUp } from 'react-icons/fi'

import NavbarAdm from '../../components/NavBarAdm'
import Products from '../../components/Products'
import Cart from '../../components/Cart'
import CartFlutter from '../../components/CartFlutter'
import useCategories from '../../util/category'
import Funcionamento from '../../components/Funcionamento'

import Footer from '../../components/Footer'
import ModalMeusPedidos from '../../components/ModalMeusPedidos'
import ModalAllProducts from '../../components/ModalAllProdutcs'

export default function NewOrder () {
  const [produtos, setProdutos] = useState([])
  const [carrinho, setCarrinho] = useState([])
  const [selectedComplementos, setSelectedComplementos] = useState([])

  const [isVisible, setIsVisible] = useState(false)

  const [showVisible, setShowVisible] = useState(false)

  const [showVisibleModalMyProducts, setShowVisibleModalMyProducts] = useState(false)

  const [showVerMais, setShowVerMais] = useState(false)

  const [obs, setObs] = useState('')
  const [quantidade, setQuantidade] = useState(1)

  const [nome, setNome] = useState('')
  const [telefone, setTelefone] = useState('')
  const [taxa, setTaxa] = useState('Selecione')
  const [pedidoMinimo, setPedidoMinimo] = useState('')
  const [openDelivery, setOpenDelivery] = useState('')
  const [closeDelivery, setCloseDelivery] = useState('')
  const [EmpresaDesativa, setEmpresaDesativa] = useState(false)

  const { funcionamentoContext } = useContext(AuthContext)
  const { id } = useParams()

  const categoryProducts = useCategories(id)

  useEffect(() => {
    loadProdutos()
    loadEmpresa()
  }, [])

  useEffect(() => {
    // Adicione um ouvinte de evento de rolagem para verificar quando o usuário rola para baixo
    const handleScroll = () => {
      if (window.scrollY > 200) { // Defina uma altura a partir da qual o botão será exibido
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  async function loadEmpresa () {
    await firebase.firestore().collection(id).doc('dados').collection('config').doc('empresa')
      .onSnapshot((doc) => {
        const data = doc.data()
        if (data) {
          setNome(data.nomeEmpresa)
          setTelefone(data.telefone)
          setOpenDelivery(data.openDelivery)
          setCloseDelivery(data.closeDelivery)
          setEmpresaDesativa(data.desativado)
          setPedidoMinimo(data.pedidoMinimo)
        }
      })
  }

  async function loadProdutos () {
    try {
      const todosRef = firebase
        .firestore()
        .collection(id)
        .doc('dados')
        .collection('produtos')
        .doc('todos')

      todosRef.onSnapshot((todosSnapshot) => {
        if (todosSnapshot.exists) {
          const todosData = todosSnapshot.data()
          const allProdutos = todosData.produtos || []

          // Filtrar apenas os produtos com campo 'active' == true
          const activeProdutos = allProdutos.filter((produto) => produto.active === true)

          // Ordenar os produtos por nome em ordem alfabética
          const sortedProdutos = activeProdutos.sort((a, b) => {
            const nomeA = a.nome.toUpperCase()
            const nomeB = b.nome.toUpperCase()
            if (nomeA < nomeB) {
              return -1
            }
            if (nomeA > nomeB) {
              return 1
            }
            return 0
          })

          setProdutos(sortedProdutos)
        }
      })
    } catch (error) {
      console.error('Erro ao carregar produtos:', error)
    }
  }

  function setCartItems (product) {
    if (!funcionamentoContext) {
      toast.warn('Loja fechada, tente mais tarde.')
      return
    }

    let selectedComplementosString = ''
    if (selectedComplementos && Object.keys(selectedComplementos).length > 0) {
      const formattedComplementos = []
      for (const item of selectedComplementos) {
        formattedComplementos.push(`${item.complemento} - ${item.quantity}x`)
      }
      selectedComplementosString = formattedComplementos.join(', ')
    }

    setCarrinho((prevState) => {
      const itemIndex = prevState.findIndex(
        carItem => carItem.product.id === product.id
      )
      // Clono o objeto product e adiciono o campo obs nele
      const newProduct = { ...product }
      newProduct.obs = obs + selectedComplementosString

      // --------PASSO MEU ARRAY DE COMPLEMENTO COM PRECO E QUANTITY
      newProduct.complementoSelecionados = selectedComplementos

      // Se for diferente de bebida ent add os intens um apos outro
      if (product.Category !== 'Bebida') {
        return prevState.concat({
          quantity: parseInt(quantidade),
          product: newProduct,
          obs: 'Observações:' + obs + ' | ' + 'Adicionais:' + selectedComplementosString
        })
        // Se nao tiver uma bebida ainda, ent add a bebida
      } else if (itemIndex < 0 && product.Category === 'Bebida') {
        return prevState.concat({
          quantity: 1,
          product: newProduct
        })
      }
      // Se ja tiver uma bebida ent so muda a quantidade dela
      const newCarItems = [...prevState]
      const item = newCarItems[itemIndex]

      newCarItems[itemIndex] = {
        ...item,
        quantity: item.quantity + 1
      }
      return newCarItems
    })
    toast.success('Item Adicionado!')
    setSelectedComplementos([])
  }

  function handleDecrementCartItem (product) {
    setCarrinho((prevState) => {
      const itemIndex = prevState.findIndex(
        carItem => carItem.product.id === product.id && carItem.product.obs === product.obs
      )
      const newCarItems = [...prevState]
      const item = newCarItems[itemIndex]

      if (item.quantity === 1) {
        newCarItems.splice(itemIndex, 1)
        return newCarItems
      }

      newCarItems[itemIndex] = {
        ...item,
        quantity: item.quantity - 1
      }

      return newCarItems
    })
    toast.warn('Item Removido!')
  }

  function handleAddCartItem (product) {
    setCarrinho((prevState) => {
      const itemIndex = prevState.findIndex(
        carItem => carItem.product.id === product.id && carItem.product.obs === product.obs
      )
      const newCarItems = [...prevState]
      const item = newCarItems[itemIndex]

      newCarItems[itemIndex] = {
        ...item,
        quantity: item.quantity + 1
      }

      return newCarItems
    })
    toast.warn('Item Adicionado!')
  }

  const categorysFilter = categoryProducts.filter((category) => category.active === true)

  if (EmpresaDesativa) {
    return <Redirect to="/" />
  }

  return (
    <>
      <NavbarAdm title='Delivery' id={id} />
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>

        {isVisible && (
          <button className="btnTop" onClick={scrollToTop}>
            <FiArrowUp size={20} color='white' />
          </button>
        )}

        <ModalMeusPedidos
          showModal={showVisibleModalMyProducts}
          setShowModal={setShowVisibleModalMyProducts}
          EmpresaTelefone={telefone}
        />

        <button className='searchLoja'
          onClick={() => setShowVisibleModalMyProducts(true)}
        >
          <MdDeliveryDining size={23} />
          Acompanhar meus pedidos
        </button>

        <Funcionamento
          closeHora={closeDelivery}
          openHora={openDelivery}
          empresaTelefone={telefone}
          pedidoMinimo={pedidoMinimo}
          key={id}
          id={id}
        />

        {!showVisible &&
          <CartFlutter
            press={() => setShowVisible(!showVisible)}
            arrayProducts={carrinho}
          />
        }

        <div className='containerAllProducts'>
          {produtos.length === 0 && <h5>Vazio!</h5>}

          <div className='SetCateg'>
            {categorysFilter.map((item) => {
              const filteredProducts = produtos.filter((product) => product.category === item.nome)
              return (
                <a key={item.nome} href={`#${item.nome}`}>{item.nome}</a>
              )
            })}
          </div>

          {categorysFilter.map((item) => {
            const filteredProducts = produtos.filter((product) => product.category === item.nome)
            return (
              <>
                {/* CATEGORYS */}
                <div className='contentCategory'>
                  <h3
                    id={item.nome.trim()}
                    style={{ marginTop: 16 }}
                  >{item.nome}
                  </h3>

                  <div
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  onClick={() => setShowVerMais(item.nome)}
                  >

                    <MdMore size={24} color='green'/>

                    <h5
                      style={{ fontWeight: 600, fontSize: 15, color: 'rgb(32,92,64)' }}

                    >Ver todos
                    </h5>
                  </div>

                  <ModalAllProducts
                    show={showVerMais === item.nome} // Renderiza o modal se o nome da categoria corresponder à categoria clicada
                    setShow={setShowVerMais}
                    product={produtos.filter(product => product.category === item.nome)} // Filtra os produtos pela categoria
                    category={item.nome}
                    complemento={produtos.complemento}
                    press={setCartItems}
                    obs={obs}
                    setObs={setObs}
                    quantidade={quantidade}
                    setQuantidade={setQuantidade}
                    carrinho={carrinho}
                    selectedComplementos={selectedComplementos}
                    setSelectedComplementos={setSelectedComplementos}
                  />
                </div>
                {/* CATEGORYS */}

                <div className='prodFilter'>
                  {/* limita produtos a 2 por vez */}
                  {filteredProducts.slice(0, 3).map((producs) => {
                    return (
                      <div style={{ width: '100%' }} key={producs.id}>
                        <Products
                          product={producs}
                          complemento={producs.complemento}
                          press={setCartItems}
                          obs={obs}
                          setObs={setObs}
                          quantidade={quantidade}
                          setQuantidade={setQuantidade}
                          carrinho={carrinho}
                          selectedComplementos={selectedComplementos}
                          setSelectedComplementos={setSelectedComplementos}
                        />
                      </div>
                    )
                  })}
                </div>
              </>
            )
          })}
        </div>

        <div style={{ paddingTop: 200 }}></div>
        <Cart
          id={id}
          arrayProducts={carrinho}
          setCarrinho={setCarrinho}
          add={handleAddCartItem}
          remove={handleDecrementCartItem}
          press={() => setShowVisible(!showVisible)}
          show={showVisible}
          obs={obs}
          funcionamento={funcionamentoContext}
          taxa={taxa}
          setTaxa={setTaxa}
          pedidoMinimo={pedidoMinimo}
          empresaTelefone={telefone}
          empresaNome={nome}
          setShowVisibleModalMyProducts={setShowVisibleModalMyProducts}
        />

      </div>
      <Footer />
    </>
  )
}
