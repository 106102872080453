import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import style from './styleGlobal.css'

import AuthProvider from './contexts/auth'

function App () {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ToastContainer autoClose={800}/>
          <Routes/>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
