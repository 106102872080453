import './style.css'
import logo from '../../assets/logoBlack.svg'

export default function NotFound () {
  return (
    <div className='notFound'>
      <img src={logo} width={250}/>
      <h2>Pagina não encontrada!</h2>
    </div>
  )
}
