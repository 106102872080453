import { useContext, useEffect, useState } from 'react'
import firebase from '../../firebaseConect'
import { Container, Header, OpenClose } from './style'
import { AuthContext } from '../../contexts/auth'

export default function Funcionamento ({ id, openHora, closeHora, empresaTelefone, pedidoMinimo }) {
  const [funcionamento, setFuncionamento] = useState('')

  const { setFuncionamentoContext } = useContext(AuthContext)

  useEffect(() => {
    loadFuncionamento()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setFuncionamentoContext(funcionamento)
    }, 200)
  })

  async function loadFuncionamento () {
    try {
      const docRef = firebase.firestore().collection(id).doc('dados').collection('config').doc('delivery')
      docRef.onSnapshot((doc) => {
        if (doc.exists) {
          setFuncionamento(doc.data().funcionamento)
        } else {
          console.log('Documento não encontrado')
        }
      })
    } catch (error) {
      console.error('Erro ao carregar os dados:', error)
    }
  }

  return (
    <Container funcionamento={funcionamento}>
    <Header funcionamento={funcionamento}>
      <p>$ Entrega</p>
      <p>A calcular</p>
    </Header>

    <Header funcionamento={funcionamento}>
      <p>$ Pedido minimo</p>
      <p>R${pedidoMinimo}</p>
    </Header>

    <Header funcionamento={funcionamento} escuro={true}>
      <p>Horário</p>
      <p>{openHora} às {closeHora}</p>
    </Header>

    <Header funcionamento={funcionamento}>
      <p>Telefone</p>
      <p>{empresaTelefone}</p>
    </Header>
    </Container>
  )
}
