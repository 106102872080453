import React, { useState } from 'react'
import './style.css'
import Modal from 'react-modal'
import Products from '../Products'

Modal.setAppElement('#root')

export default function ModalAllProducts ({
  show, setShow,
  product,
  category,
  complemento,
  press,
  obs,
  setObs,
  quantidade,
  setQuantidade,
  carrinho,
  selectedComplementos,
  setSelectedComplementos
}) {
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  // Filtrar os produtos com base no termo de pesquisa
  const filteredProducts = product.filter(item =>
    item.nome && item.nome.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const productsToDisplay = searchTerm.trim() === '' ? product : filteredProducts

  return (
    <div>
      {show &&
        <div className='modalProduct'>
          <div className='modalContentProduct'>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 16,
              paddingRight: 8
            }}>
              <h3>{category}</h3>
              <h4 onClick={() => setShow(false)}
                style={{ backgroundColor: 'red', maxWidth: 56, padding: 4, color: '#fff', borderRadius: 4, cursor: 'pointer', marginTop: 8, marginBottom: 8 }}
              >
                fechar
              </h4>
            </div>

            <input
              type="text"
              placeholder="Pesquisar produtos..."
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ width: '90%', margin: 8, borderRadius: 6, border: 'solid 1px #000', padding: 8 }}
            />

            <div className='modalProdutosCOntent'>
              {productsToDisplay.length > 0
                ? (
                    productsToDisplay.map((item) => (
                  <Products
                    key={item.id}
                    product={item}
                    complemento={item.complemento}
                    press={press}
                    obs={obs}
                    setObs={setObs}
                    quantidade={quantidade}
                    setQuantidade={setQuantidade}
                    carrinho={carrinho}
                    selectedComplementos={selectedComplementos}
                    setSelectedComplementos={setSelectedComplementos}
                  />
                    ))
                  )
                : (
                <p>Nenhum produto encontrado.</p>
                  )}
            </div>
            <hr />
          </div>
        </div>
      }
    </div>
  )
}
