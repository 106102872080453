import './style.css'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../contexts/auth'
import firebase from '../../firebaseConect'

export default function NavbarAdm ({ title, id }) {
  const [funcionamento, setFuncionamento] = useState('')

  const { setFuncionamentoContext } = useContext(AuthContext)

  useEffect(() => {
    loadFuncionamento()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setFuncionamentoContext(funcionamento)
    }, 200)
  })

  async function loadFuncionamento () {
    try {
      const docRef = firebase.firestore().collection(id).doc('dados').collection('config').doc('delivery')
      docRef.onSnapshot((doc) => {
        if (doc.exists) {
          setFuncionamento(doc.data().funcionamento)
        } else {
          console.log('Documento não encontrado')
        }
      })
    } catch (error) {
      console.error('Erro ao carregar os dados:', error)
    }
  }

  const [banner, setBanner] = useState('')
  const [logo, setLogo] = useState('')
  const [nome, setNome] = useState('')

  useEffect(() => {
    loadBanner()
    loadLogo()
    loadEmpresa()
  }, [])

  async function loadEmpresa () {
    try {
      const docRef = await firebase.firestore().collection(id).doc('dados').collection('config').doc('empresa').get()

      if (docRef.exists) {
        setNome(docRef.data().nomeEmpresa)
      } else {
        console.log('Documento não encontrado.')
      }
    } catch (error) {
      console.error('Erro ao carregar o banner:', error)
    }
  }

  async function loadBanner () {
    try {
      const docRef = await firebase.firestore().collection(id).doc('dados').collection('config').doc('banner').get()

      if (docRef.exists) {
        setBanner(docRef.data().bannerUrl)
      } else {
        console.log('Documento não encontrado.')
      }
    } catch (error) {
      console.error('Erro ao carregar o banner:', error)
    }
  }

  async function loadLogo () {
    try {
      const docRef = await firebase.firestore().collection(id).doc('dados').collection('config').doc('logo').get()

      if (docRef.exists) {
        setLogo(docRef.data().logoUrl)
      } else {
        console.log('Documento não encontrado.')
      }
    } catch (error) {
      console.error('Erro ao carregar o banner:', error)
    }
  }

  const containerStyle = {
    width: '100%',
    height: '200px',
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(${banner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 30px',
    position: 'relative'
  }

  const textStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#ffffff',
    fontSize: '24px',
    width: '100%',
    padding: '80px 0', // Espaçamento superior e inferior para centralizar verticalmente
    boxSizing: 'border-box' // Garante que o padding não aumente o tamanho do contêiner
  }

  return (
    <div className='NavLogo'>
      <div style={containerStyle}>
        {!funcionamento &&
        <div style={textStyle}>Loja Fechada</div>
        }
      </div>
      <div className='contentLogoNavbarAdm'>
        <div className='contentLogo'>
          <img src={logo} alt="" className='imgLogoNav'/>

          <div>
            <h3 style={{ whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', width: 220, fontSize: 18 }}>{nome}</h3>
            <p>{title}</p>
          </div>
       </div>

       <h5>Delivery</h5>
      </div>
    </div>
  )
}
