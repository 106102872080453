import { useState } from 'react'
import Modal from 'react-modal'
import './style.css'

Modal.setAppElement('#root')

export default function ModalSucessPedido ({ showModal, setShowModal, setShowVisibleModalMyProducts, setCarrinho }) {
  function closeModal () {
    setShowModal(false)
    setCarrinho([])
    setShowVisibleModalMyProducts(true)
  }

  return (
    <div className="Container">
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel=""
        overlayClassName="modal-overlay-sucessPedido"
        className="modal-content-sucessPedido"
      >
        <div className='containerModalSucessPedido'>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 16 }}>
            <h2>Prontinho!</h2>
            <h4>Já recebemos seu pedido, agora é aguardar a entrega!</h4>
            <p>Caso deseje fazer alguma alteração no seu pedido, fale conosco no whatsapp.</p>
          </div>

          <button onClick={closeModal}>OK</button>
        </div>
      </Modal>
    </div>
  )
}
