import { useState, createContext, useEffect } from 'react'
import firebase from '../firebaseConect'

export const AuthContext = createContext({})

export default function AuthProvider ({ children }) {
  const [user, setUser] = useState(null)
  const [load, setLoad] = useState(true)

  const [loadAuth, setLoadAuth] = useState(false)
  const [funcionamento, setFuncionamento] = useState(null)
  const [funcionamentoContext, setFuncionamentoContext] = useState(null)
  const [openHora, setOpenHora] = useState('')
  const [closeHora, setCloseHora] = useState('')

  //    pega do local storage os dados q a gnt cadastrou e seta na state user os valores
  useEffect(() => {
    function loadStorage () {
      const storageUser = localStorage.getItem('usuario')

      if (storageUser) {
        setUser(JSON.parse(storageUser))
        setLoad(false)
      }

      setLoad(false)
    }

    loadStorage()
    loadConfig()
  }, [])

  async function loadConfig () {
    await firebase.firestore().collection('config')
      .onSnapshot((doc) => {
        doc.forEach((item) => {
          setFuncionamento(item.data().funcionamento)
          setOpenHora(item.data().openHora)
          setCloseHora(item.data().closeHora)
        })
      })
  }

  // função de fazer o login
  async function signIn (email, password) {
    setLoadAuth(true)
    // busca no firebase o usuario com esse email e senha

    await firebase.auth().signInWithEmailAndPassword(email, password)
    // pega o id
      .then(async (value) => {
        const uid = value.user.uid
        // salva os dados que tem com esse id
        const userProfile = await firebase.firestore().collection('users')
          .doc(uid)
          .get()

        const data = {
          // uid:uid,
          uid: value.user.uid,
          nome: userProfile.data().nome,
          senha: userProfile.data().senha,
          avatar: userProfile.data().avatarUrl,
          faltas: userProfile.data().faltas,
          email: value.user.email

        }
        setUser(data)
        storageUser(data)
        setLoadAuth(false)
        // toast.success('Bem vindo de volta!')
      })
      .catch((error) => {
        console.log(error)
        // toast.error('algo deu errado')
      })
    setLoadAuth(false)
  }

  // Função de cadastrar e relacionar os dados para o banco de dados
  async function signUp (email, password, nome) {
    setLoadAuth(true)
    await firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(async (value) => {
        const uid = value.user.uid

        // cadastrar no banco de dados
        await firebase.firestore().collection('users')
          .doc(uid).set({
            email,
            senha: password,
            avatarUrl: null,
            nome
          })

        // pega os dados que tem esse id e salva na state User
          .then(() => {
            const data = {
              uid,
              email,
              senha: password,
              avatarUrl: null,
              nome
              // nome: nome
              // foto: foto
            }

            setUser(data)
            storageUser(data)
            setLoadAuth(false)
            // toast.success('Bem vindo!');
          })
      })
    // Caso der erro mostra no console
      .catch((error) => {
        console.log(error)
        setLoadAuth(false)
        // toast.error('Algo deu errado');
      })
  }

  // salva os dados no localstorage
  function storageUser (data) {
    localStorage.setItem('usuario', JSON.stringify(data))
  }

  // função deslogar

  async function logout () {
    await firebase.auth().signOut()
    // remove do localstorage
    localStorage.removeItem('usuario')
    // volta a state user para null
    setUser(null)
  }

  return (
        <AuthContext.Provider value={{
          signed: !!user,
          user,
          load,
          signUp,
          logout,
          signIn,
          loadAuth,
          funcionamento,
          openHora,
          closeHora,
          setFuncionamentoContext,
          funcionamentoContext
        }}>
            {children}
        </AuthContext.Provider>
  )
}
