import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const produção = {
  apiKey: 'AIzaSyAgtjj86Kmi4o0NZppZgzMolheMrwQOaso',
  authDomain: 'meuappdelivery-8450e.firebaseapp.com',
  projectId: 'meuappdelivery-8450e',
  storageBucket: 'meuappdelivery-8450e.appspot.com',
  messagingSenderId: '606215796009',
  appId: '1:606215796009:web:6f62b548fdf843f6c8f338'
}

const dev = {
  apiKey: 'AIzaSyBT8G1nKuTNDVqURLaPBFvXhfDfmj1qL5M',
  authDomain: 'desenvolvimentodelivery.firebaseapp.com',
  projectId: 'desenvolvimentodelivery',
  storageBucket: 'desenvolvimentodelivery.appspot.com',
  messagingSenderId: '995084487675',
  appId: '1:995084487675:web:147ac7f3b138dfc7a8195e'
}

const dadosBd = produção

// se n tem uma conexao aberta, abra uma
if (!firebase.apps.length) {
  // Initialize Firebase
  firebase.initializeApp(dadosBd)
}

export default firebase
