import { useState } from 'react'
import './style.css'
import ModalProduct from '../../components/ModalProduct'
import { MdAddShoppingCart } from 'react-icons/md'

export default function Products ({ product, press, obs, setObs, quantidade, setQuantidade, complemento, carrinho, selectedComplementos, setSelectedComplementos }) {
  const [show, setShow] = useState(false)

  function handleAdd () {
    if (product.Category !== 'Bebida') {
      setShow(!show)
    } else {
      press(product)
    }
  }

  return (
    <>
    <ModalProduct
    show={show}
    setShow={setShow}
    product={product}
    press={press}
    obs={obs}
    setObs={setObs}
    quantidade={quantidade}
    setQuantidade={setQuantidade}
    complemento={complemento}
    carrinho={carrinho}
    selectedComplementos={selectedComplementos}
    setSelectedComplementos={setSelectedComplementos}
    />

    <div className='containerProducts'>

      <div className='imgProducts'>
        <img src={product.image} alt="" />

        <div className='infosProducts'>
          <h5>{product.nome}</h5>
          <p className='desc'>{product.desc}</p>
          <p className='precoo'>R${parseFloat(product.preco).toFixed(2)}</p>
        </div>
      </div>

      <div className='descProducts'>
        <div className='addProducts' onClick={handleAdd}>
         <MdAddShoppingCart size={20} color='#fff'/>
        </div>
      </div>

    </div>
    </>
  )
}
