import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color:  ${(props) => props.funcionamento ? '#4FAF2E' : '#AF2E2E'};
  border-radius: 10px;
  margin-bottom: 32px;
`
export const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 4px;

  p{
    font-size: 12px;
    color: white;
  }
`
