import './style.css'
import { useEffect, useState } from 'react'
import firebase from '../../firebaseConect'
import Modal from 'react-modal'
import { toast } from 'react-toastify'

import { GrClose } from 'react-icons/gr'
Modal.setAppElement('#root')

export default function ModalMesa ({ taxa, setTaxa, nome, setNome, endereco, telefone, setTelefone, email, setEmail, setEndereco, pagamento, setPagamento, troco, setTroco, retirada, setRetirada, showModalMesa, setShowModalMesa, id, setBairro }) {
  const [progress, setProgress] = useState(false)
  const [dataArrayBairro, setDataArrayBairro] = useState([])

  useEffect(() => {
    loadBairros()
  }, [])

  useEffect(() => {
    if (retirada === 'Sim') {
      setEndereco('')
      setTaxa('')
      setBairro('')
    }
  }, [retirada])

  // LoadBairrosArray
  function loadBairros () {
    try {
      const todosRef = firebase
        .firestore()
        .collection(id)
        .doc('dados')
        .collection('bairros')
        .doc('todos')

      todosRef.onSnapshot((docSnapshot) => {
        if (docSnapshot.exists) {
          const todosData = docSnapshot.data()
          const bairrosO = todosData.bairros || []
          setDataArrayBairro(bairrosO)
        } else {
          // Se o documento não existir, seta um array vazio
          setDataArrayBairro([])
        }
      })
    } catch (error) {
      console.error('Erro ao carregar bairros:', error)
    }
  }

  function closeModal () {
    let errorMessage = ''

    if (nome === '') {
      errorMessage = 'Preencha o nome.'
    } else if (email === '') {
      errorMessage = 'Preencha o email.'
    } else if (taxa === 'Selecione') {
      errorMessage = 'Selecione seu bairro.'
    } else if (retirada === 'Sim') {
      errorMessage = ''
    } else if (endereco === '') {
      errorMessage = 'Preencha o seu endereço.'
    }

    if (errorMessage === '') {
      localStorage.setItem('telefoneUserACESSAAI', telefone)
      localStorage.setItem('emailUserACESSAAI', email)
      setShowModalMesa(false)
      handleSubmit()
    } else {
      toast.error(errorMessage)
    }
  }

  async function handleSubmit (url) {
    if (email === '') {
      console.log('email não cadastrado')
      return
    }

    setProgress(true)
    const novoLead = {
      id: new Date(),
      nome,
      email,
      telefone
    }

    try {
      const docRef = firebase.firestore().collection(id).doc('dados').collection('leads').doc('todos')

      // Recupera o documento existente "todos" na subcoleção "produtos"
      const docSnapshot = await docRef.get()

      if (docSnapshot.exists) {
        // Se o documento existir, atualize o array de produtos nele
        const dados = docSnapshot.data()
        const leadsExistente = dados.leads || []

        // Verifique se o email já existe na lista de leads
        const emailExiste = leadsExistente.some((lead) => lead.email === novoLead.email)

        if (!emailExiste) {
          leadsExistente.push(novoLead)
          await docRef.update({ leads: leadsExistente })
          // toast.success('Sucesso!')
        } else {
          console.log('email já cadastrado')
        }
      } else {
        // Se o documento não existir, crie um novo com o array de produtos
        await docRef.set({ leads: [novoLead] })
        // toast.success('Sucesso!')
      }

      setProgress(false)
    } catch (error) {
      setProgress(false)
    }
  }

  // function handleSelectChange (e) {
  //   const selectedValue = e.target.value
  //   if (selectedValue !== 'Selecione') {
  //     const selectedBairro = dataArrayBairro.find((item) => item.taxa === parseFloat(selectedValue))

  //     setTaxa(parseFloat(selectedValue))
  //     // setBairro(selectedBairro.bairro)

  //     console.log(e.target.value)
  //   } else {
  //     setTaxa('Selecione')
  //     setBairro('')
  //   }
  // }

  function handleSelectChange (e) {
    const selectedValue = e.target.value

    if (selectedValue !== 'Selecione') {
      const [taxaValue, bairroValue] = selectedValue.split('?') // Divide o valor antes e depois do '?'

      setTaxa(parseFloat(taxaValue)) // Define o valor antes do '?' na state 'taxa'
      setBairro(bairroValue) // Define o valor depois do '?' na state 'bairro'
    } else {
      setTaxa('Selecione') // Ou qualquer valor que represente 'Selecione' para a state 'taxa'
      setBairro('') // Define a state 'bairro' como vazia
    }
  }

  return (
    <div className="Container">
      <Modal
        isOpen={showModalMesa}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        overlayClassName="modal-overlay"
        className="modal-content"
      >
        <div className='containerModalMesa'>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <h4>Preencha os dados</h4>
            <GrClose size={16} onClick={closeModal} style={{ cursor: 'pointer' }} />
          </div>

          <div className='formModalMesa'>
            <input type="text" placeholder='Nome' onChange={(e) => setNome(e.target.value)} value={nome} />

            <h4 style={{ marginTop: 8 }}>Retirar no local?</h4>
            <select placeholder='Retirada' onChange={(e) => setRetirada(e.target.value)} style={{ width: '100%' }} value={retirada}>
              <option value="Não">Não</option>
              <option value="Sim">Sim</option>
            </select>

            {retirada === 'Não' && (
              <>
                <h4>Bairro:</h4>
                <select style={{ width: '100%' }} onChange={(e) => handleSelectChange(e)}>
                  <option value="Selecione">Selecione</option>
                  {dataArrayBairro.map((item) => (
                    <option key={item.id} value={item.taxa + '?' + item.bairro}>
                      {item.bairro} - R${parseFloat(item.taxa).toFixed(2)}
                    </option>
                  ))}
                </select>
              </>
            )}

            <h4>Endereço:</h4>
            {retirada === 'Não' &&
              <input type="text" placeholder='Rua, número, ponto de referência.' onChange={(e) => setEndereco(e.target.value)} value={endereco} />
            }

            <h4 style={{ marginTop: 8, marginBottom: 0 }}>Telefone</h4>
            <input type="text" placeholder='(82) 90000-0000' onChange={(e) => setTelefone(e.target.value)} value={telefone} />

            <h4 style={{ marginTop: 8, marginBottom: 0 }}>Email</h4>
            <input type="text" placeholder='email@email.com' onChange={(e) => setEmail(e.target.value)} value={email} />

            <h4 style={{ marginTop: 8 }}>Selecione a forma de pagamento</h4>
            <select placeholder='Forma de pagamento' onChange={(e) => setPagamento(e.target.value)} style={{ width: '100%' }} value={pagamento}>
              <option value="Pix">Pix</option>
              <option value="Dinheiro">Dinheiro</option>
              <option value="Débito">Débito</option>
              <option value="Crédito">Crédito</option>
            </select>

            {pagamento === 'Dinheiro' &&
              <>
                <h4 style={{ marginTop: 16, marginBottom: 8 }}>Troco? </h4>
                <input type="text" placeholder='Digite o valor da nota' onChange={(e) => setTroco(e.target.value)} value={troco} />
              </>
            }
          </div>

          <button onClick={closeModal}>Salvar</button>
        </div>
      </Modal>
    </div>
  )
}
