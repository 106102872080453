import './style.css'

import Modal from 'react-modal'
import { IoIosAddCircleOutline, IoMdAdd, IoIosRemove } from 'react-icons/io'
import { toast } from 'react-toastify'

Modal.setAppElement('#root')

export default function ModalProduct ({ show, setShow, product, press, obs, setObs, quantidade, setQuantidade, complemento, carrinho, selectedComplementos, setSelectedComplementos }) {
  // -----------------------------------
  // const totalQuantity = selectedComplementos.reduce((accumulator, item) => {
  //   return accumulator + item.quantity
  // }, 0)

  // const maxComplementos = 2
  // -----------------------------------

  function closeModalProduct () {
    setShow(!show)
    setQuantidade(parseInt(1))
    setObs('')
    setSelectedComplementos([])
  }

  function setCartItem () {
    press(product)
    setShow(!show)
    setQuantidade(parseInt(1))
    setObs('')
  }

  function handleAddComplemento (item) {
    // if (maxComplementos) {
    //   if (totalQuantity >= maxComplementos) {
    //     toast.warning('Maximos Complementos')
    //     return
    //   }
    // }

    const foundIndex = selectedComplementos.findIndex(compl => compl.complemento === item.complemento)

    if (foundIndex === -1) {
      setSelectedComplementos([...selectedComplementos, { complemento: item.complemento, preco: item.preco, quantity: item.quantity + 1 }])
    } else {
      const updatedComplementos = [...selectedComplementos]
      updatedComplementos[foundIndex].quantity++
      setSelectedComplementos(updatedComplementos)
    }
  }

  function handleRemoveComplemento (item) {
    const foundIndex = selectedComplementos.findIndex(compl => compl.complemento === item.complemento)

    if (foundIndex !== -1) {
      const updatedComplementos = [...selectedComplementos]
      if (updatedComplementos[foundIndex].quantity > 1) {
        updatedComplementos[foundIndex].quantity--
      } else {
        updatedComplementos.splice(foundIndex, 1)
      }
      setSelectedComplementos(updatedComplementos)
    }
  }

  return (
    <div>
      {show &&
        <div className='modalProduct'>
          <div className='modalContentProduct' >
            <img src={product.image} className='bannerModalProduct' />

            <div className='contentInfoModalProduct'>
              <h4>{product.nome}</h4>
              <p>{product.desc}</p>

              <div className='contentFormModalProduct'>
                {/* COMPLEMENTOS */}
                {complemento && complemento.map((item, index) => {
                  const foundIndex = selectedComplementos.findIndex(compl => compl.complemento === item.complemento)
                  const quantidadeSelecionada = foundIndex !== -1 ? selectedComplementos[foundIndex].quantity : 0
                  return (
                    <div key={index} className='adicionais'>
                      <p><strong>{item.complemento}</strong> +R${parseFloat(item.preco).toFixed(2)}</p>

                      <div className='ContainerBtnAdicionais'>
                        <div className='btnQuantity' onClick={() => handleRemoveComplemento(item)} >
                          <IoIosRemove size={20} />
                        </div>

                        <h3>{quantidadeSelecionada}</h3>

                        <div className='btnQuantity' onClick={() => handleAddComplemento(item)} >
                          <IoMdAdd size={20} />
                        </div>

                      </div>
                    </div>
                  )
                })}
                {/* COMPLEMENTOS */}

                <h5 style={{ marginTop: 24 }}>Observações</h5>
                <textarea onChange={(e) => setObs(e.target.value)} value={obs} />

                {product.Category !== 'Bebida' && <>
                  <h5>Quantidade</h5>
                  <div className='contentFormModalProductQtd'>

                    <div className='btnQtd' onClick={() => setQuantidade(quantidade - 1)}>
                      <h5>-</h5>
                    </div>

                    <input type="number" onChange={(e) => setQuantidade(parseInt(e.target.value))} value={quantidade} />

                    <div className='btnQtd' onClick={() => setQuantidade(parseInt(quantidade + 1))}>
                      <h5>+</h5>
                    </div>
                  </div>
                </>}

              </div>
            </div>

            <div className='footerModalProduct'>
              <div className='btnCloseProduct' onClick={closeModalProduct}>
                <h5>Fechar</h5>
              </div>

              <div className='btnaddProduct' onClick={setCartItem}>
                <IoIosAddCircleOutline size={20} />
                <h5>Adicionar</h5>
              </div>
            </div>

          </div>
        </div>
      }
    </div>
  )
}
