import firebase from '../firebaseConect'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/auth'

export default function useCategories (id) {
  const { controlCategory } = useContext(AuthContext)
  const [categories, setCategories] = useState([])

  useEffect(() => {
    loadCategories()
  }, [])

  async function loadCategories () {
    try {
      const produtosRef = firebase.firestore().collection(id).doc('dados').collection('categorys').doc('todos')

      produtosRef.onSnapshot((docSnapshot) => {
        if (docSnapshot.exists) {
          const dados = docSnapshot.data()
          const categorias = dados.categories || []

          // Ordenar as categorias em ordem ascendente com base no campo "order"
          categorias.sort((a, b) => a.order - b.order)

          setCategories(categorias)
        } else {
          setCategories([])
        }
      })
    } catch (error) {
      console.error('Erro ao carregar categorias:', error)
    }
  }
  return categories
}
