import './style.css'
import { useEffect, useState } from 'react'
import firebase from '../../firebaseConect'
import { toast } from 'react-toastify'

import { IoMdAdd, IoIosRemove } from 'react-icons/io'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { GrClose } from 'react-icons/gr'

import ModalMesa from '../ModalMesa'
import ModalSucessPedido from '../ModalSucessPedido'
import { setAppElement } from 'react-modal'

export default function Cart ({ id, taxa, setTaxa, arrayProducts, add, remove, press, show: showVisible, obs, setCarrinho, funcionamento, empresaTelefone, empresaNome, setShowVisibleModalMyProducts, pedidoMinimo }) {
  const [showModalMesa, setShowModalMesa] = useState(false)

  // Inicializa o estado de showModal com o valor armazenado no localStorage (ou false se não existir)
  const [showModal, setShowModal] = useState(
    JSON.parse(localStorage.getItem('showModal')) || false
  )
  // Atualiza o valor do showModal no localStorage sempre que ele mudar
  useEffect(() => {
    localStorage.setItem('showModal', JSON.stringify(showModal))
  }, [showModal])

  //  ------------------------------------------------------------------
  const [idAnterior, setIdAnterior] = useState(
    JSON.parse(localStorage.getItem('idAnterior'))
  )
  // Atualiza o valor do showModal no localStorage sempre que ele mudar
  useEffect(() => {
    localStorage.setItem('idAnterior', JSON.stringify(id))
  }, [])
  //  ------------------------------------------------------------------

  const [nome, setNome] = useState('')
  const [endereco, setEndereco] = useState('')
  const [bairroSelect, setBairro] = useState('')

  console.log(`bairro:${bairroSelect}`)
  console.log(`taxa:${taxa}`)

  const endereçoCompleto = `${bairroSelect} - ${endereco}`

  const [telefone, setTelefone] = useState(
    localStorage.getItem('telefoneUserACESSAAI') || ''
  )

  const [email, setEmail] = useState(
    localStorage.getItem('emailUserACESSAAI') || ''
  )

  const [pagamento, setPagamento] = useState('Pix')
  const [troco, setTroco] = useState('Não')
  const [retirada, setRetirada] = useState('Não')

  const [progress, setProgress] = useState(false)

  const data = new Date()
  const dia = String(data.getDate()).padStart(2, '0')
  const mes = String(data.getMonth() + 1).padStart(2, '0')
  const ano = String(data.getFullYear()).padStart(2, '0')
  const DateFormated = dia + '/' + mes + '/' + ano

  const total = arrayProducts.reduce((acc, item) => {
    const productPrice = parseFloat(item.product.preco)

    // Calcula o preço total dos complementos selecionados para este item
    const complementosTotal = item.product.complementoSelecionados.reduce(
      (total, complemento) =>
        total + parseFloat(complemento.preco) * complemento.quantity,
      0
    )

    return acc + item.quantity * (productPrice + complementosTotal)
  }, 0)

  const totalItems = arrayProducts.reduce((acc, item) => {
    return acc + item.quantity
  }, 0)

  async function handlePedido () {
    if (nome === '') {
      setShowModalMesa(true)
      toast.warning('Preencha todos os dados!')
      return
    }

    setProgress(true)

    const chars = '0123456789'
    const passwordLength = 4
    let password = ''

    for (let i = 0; i < passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length)
      password += chars.substring(randomNumber, randomNumber + 1)
    }

    const phoneNumber = `55${empresaTelefone}`
    let valorTotal

    if (retirada === 'Não') {
      valorTotal = parseFloat(total) + parseFloat(taxa)
    } else {
      valorTotal = parseFloat(total)
    }

    let messageTemp = `*✅ ${empresaNome} Delivery*\n\n` +
      `*🗒️Novo Pedido* - ${totalItems} Itens\n\n` +
      `*Cliente:* ${nome}\n\n` +
      arrayProducts.map((item) => '*' + item.quantity + 'x' + ' ' + item.product.nome.trim() + '*' + ' - ' + item.product.obs + '\n' + '------------').join('\n\n') + '\n\n' +
      '*Valor*' + `\nProdutos: R$${total}`

    if (retirada === 'Não') {
      messageTemp += `\nTaxa de entrega: R$${taxa}`
    }

    messageTemp += `\nTotal: R$${valorTotal}` + '\n\n' +
      '*Forma de pagamento*' + `\n${pagamento}` + `\nTroco: R$${troco}` + '\n\n' +
      '*Entrega*' + `\n${endereçoCompleto}` + `\nRetirada no local: ${retirada}`

    const message = messageTemp

    const apiUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`
    handlleBd(apiUrl)
  }

  async function handlleBd (url) {
    let valorTotal

    if (retirada === 'Não') {
      valorTotal = parseFloat(total) + parseFloat(taxa)
    } else {
      valorTotal = parseFloat(total)
    }

    if (!funcionamento) {
      toast.warn('Loja fechada, tente mais tarde.')
      setProgress(false)
      return
    }

    if (pedidoMinimo > valorTotal) {
      toast.warn(`Pedido mínimo: R$${pedidoMinimo}`)
      setProgress(false)
      return
    }

    await firebase.firestore().collection(id).doc('dados').collection('pedidos')
      .add({
        nome,
        obs,
        qtdItems: totalItems,
        items: arrayProducts,
        total: valorTotal,
        status: 'Fila de espera',
        dateCreated: new Date(),
        dateFormated: DateFormated,
        delivery: 1,
        endereco: endereçoCompleto,
        telefone,
        pagamento,
        troco,
        retirada,
        taxa: parseFloat(taxa)
      })
      .then(() => {
        toast('Pedido feito com sucesso!')
        setShowModal(true)
        setProgress(false)
        setShowVisibleModalMyProducts(true)

        setTimeout(() => {
          window.location.href = url
        }, 1500)

        setCarrinho([])
      })

      .catch((e) => {
        console.log(e)
        setProgress(false)
      })
  }

  function EditEndereço () {
    setTaxa('Selecione')
    setEndereco('')
    setShowModalMesa(true)
  }

  return (
    <>
      {showModalMesa &&
        <ModalMesa
          taxa={taxa}
          setTaxa={setTaxa}
          nome={nome}
          setNome={setNome}
          endereco={endereco}
          setEndereco={setEndereco}
          telefone={telefone}
          setTelefone={setTelefone}
          email={email}
          setEmail={setEmail}
          pagamento={pagamento}
          setPagamento={setPagamento}
          troco={troco}
          setTroco={setTroco}
          retirada={retirada}
          setRetirada={setRetirada}
          showModalMesa={showModalMesa}
          setShowModalMesa={setShowModalMesa}
          id={id}
          setBairro={setBairro}
        />
      }

      {showModal &&
        idAnterior === id &&
        <ModalSucessPedido
          showModal={showModal}
          setShowModal={setShowModal}
          setShowVisibleModalMyProducts={setShowVisibleModalMyProducts}
          setCarrinho={setCarrinho}
        />
      }

      <div className={showVisible ? 'containerCart' : 'containerCartClose'}>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 8, marginBottom: 16 }}>
          <AiOutlineShoppingCart size={20} />
          <GrClose size={18} onClick={press} style={{ minHeight: 20, cursor: 'pointer' }} />
        </div>

        <div className='scroll'>
          {arrayProducts.map((item) => {
            return (
              <div key={item.product.id} className='productCartItem'>
                <img src={item.product.image} alt="" />
                <div className='detailsProduct'>
                  <div>
                    <p>{item.product.nome}</p>
                    <p>{item.quantity}x</p>
                    <p className='ObsCart'>{item.obs ? `(${item.obs})` : ''}</p>
                  </div>

                  <p>
                    R${parseFloat(item.product.preco) +
                      item.product.complementoSelecionados.reduce(
                        (total, complemento) => total + parseFloat(complemento.preco) * complemento.quantity,
                        0
                      )}
                  </p>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>

                      <div className='btnQuantity' onClick={() => remove(item.product)}>
                        <IoIosRemove size={20} />
                      </div>

                      <div className='btnQuantity' onClick={() => add(item.product)}>
                        <IoMdAdd size={20} />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <div className='footer'>
          <h5>Itens: {totalItems}</h5>

          <h5>Subtotal: {total}</h5>

          {!isNaN(parseFloat(taxa)) &&
          <>

            <h5
              style={{ marginTop: 4, marginBottom: 4 }}
            >Taxa de entrega:
            {retirada === 'Não'
              ? `R$${parseFloat(taxa).toFixed(2)}`
              : 'R$0'
            }
            </h5>

            <h5
              style={{ marginTop: 4, marginBottom: 4 }}
            >Total:
            {retirada === 'Não'
              ? `R$${parseFloat(total) + parseFloat(taxa)}`
              : `R$${parseFloat(total)}`
            }
            </h5>
          </>
          }
        </div>

        <button
          className='btnCartPedido'
          onClick={handlePedido}
          disabled={progress}
        >
          <p>{progress ? '...' : 'Fazer pedido'}</p>
        </button>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16 }}>

          <p style={{ fontSize: 12, textDecoration: 'underline', cursor: 'pointer', color: 'red' }} onClick={() => setCarrinho([])}>Limpar Carrinho</p>

          <p style={{ fontSize: 12, textDecoration: 'underline', cursor: 'pointer' }} onClick={EditEndereço}>editar endereço</p>
        </div>

      </div>
    </>
  )
}
