import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Modal from 'react-modal'
import './style.css'

import firebase from '../../firebaseConect'
import logo from '../../assets/logoBlack.svg'
import MyPedidos from '../MyPedidos'

Modal.setAppElement('#root')

export default function ModalMeusPedidos ({ showModal, setShowModal, EmpresaTelefone }) {
  const { id } = useParams()
  const [pedidos, setPedidos] = useState([])

  const [telefoneCliente, setTelefoneCliente] = useState(
    localStorage.getItem('telefoneUserACESSAAI') || ''
  )

  useEffect(() => {
    loadPedidos()
  }, [])

  async function loadPedidos () {
    await firebase.firestore().collection(id).doc('dados').collection('pedidos')
      .where('telefone', '==', `${telefoneCliente}`)
      .orderBy('dateCreated', 'desc')
      .onSnapshot((doc) => {
        const meusPosts = []

        doc.forEach((item) => {
          meusPosts.push({
            id: item.id,
            nome: item.data().nome,
            dateFormated: item.data().dateFormated,
            items: item.data().items,
            total: item.data().total,
            status: item.data().status,
            pagamento: item.data().pagamento,
            telefone: item.data().telefone
          })
        })
        setPedidos(meusPosts)
      })
  }

  function closeModal () {
    setShowModal(false)
  }

  return (
    <div className="Container">
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel=""
        overlayClassName="modal-overlay-MeusPedidos"
        className="modal-content-MeusPedidos"
      >
        <div className='containerModalMeusPedidos'>

          <div className='containerModalMPRODUCTS'>
            <img src={logo} width={100} />

            {/* <button onClick={closeModal} className='btnMyPedidos'>Fechar</button> */}

            <div className='containerPedidos'>
              {pedidos.map((item) => {
                return (
                  <MyPedidos
                    key={item.id}
                    nome={item.nome}
                    data={item.dateFormated}
                    items={item.items}
                    status={item.status}
                    total={item.total}
                    pagamento={item.pagamento}
                    EmpresaTelefone={EmpresaTelefone}
                  />
                )
              })}
            </div>
          </div>

        </div>
      </Modal>
    </div>
  )
}
