import './style.css'
import React, { useEffect, useRef, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'

import { AiFillCloseCircle } from 'react-icons/ai'
import { FaWhatsapp } from 'react-icons/fa'

import ScrollReveal from 'scrollreveal'

import Footer from '../../components/Footer'

import logo from '../../assets/logo.svg'
import logoBlack from '../../assets/logoBlack.svg'
import celular from '../../assets/celular.png'
import banner from '../../assets/banner2.jpg'

export default function LandingPage () {
  // PIXEL DO FACEBOOK
  const advancedMatching = {} // Pode deixar vazio ou preenchê-lo com dados avançados se necessário
  const options = {
    autoConfig: true, // Configura automaticamente os eventos padrão, como PageView, ViewContent, etc.
    debug: false // Define como true para habilitar o modo de depuração
  }
  ReactPixel.init('1267026513978235', advancedMatching, options)
  // PIXEL DO FACEBOOK

  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [inpCelular, setCelular] = useState('')
  const [delivery, setDelivery] = useState('')

  // Animações
  window.sr = ScrollReveal({ reset: true })
  const animeRight = useRef(null)
  const animeLeft = useRef(null)
  const animeOpacity = useRef(null)
  const animeZoom = useRef(null)
  const animeText = useRef(null)
  const animeProduto = useRef(null)
  const animeSection4 = useRef(null)
  const animeSection5 = useRef(null)
  const animeForm = useRef(null)
  const animeSection6 = useRef(null)
  //

  useEffect(() => {
    ReactPixel.track('ViewContent', {
      content_name: 'Acessou LandinPage'
      // content_category: 'Categoria do Conteúdo',
      // content_ids: ['ID do Conteúdo'] // Se você tiver IDs de conteúdo
    })

    const scrollReveal = ScrollReveal()

    // right
    scrollReveal.reveal(animeProduto.current, {
      duration: 800, // Duração da animação em milissegundos
      origin: 'hight', // Origem da animação (esquerda)
      distance: '30px', // Distância de animação
      opacity: 1, // Opacidade final
      easing: 'ease-in-out'// Efeito de easing
    })

    // right
    scrollReveal.reveal(animeForm.current, {
      duration: 800, // Duração da animação em milissegundos
      origin: 'hight', // Origem da animação (esquerda)
      distance: '30px', // Distância de animação
      opacity: 1, // Opacidade final
      easing: 'ease-in-out'// Efeito de easing
    })

    // right
    scrollReveal.reveal(animeRight.current, {
      duration: 800, // Duração da animação em milissegundos
      origin: 'hight', // Origem da animação (esquerda)
      distance: '30px', // Distância de animação
      opacity: 1, // Opacidade final
      easing: 'ease-in-out'// Efeito de easing
    })

    // left
    scrollReveal.reveal(animeLeft.current, {
      duration: 800, // Duração da animação em milissegundos
      origin: 'left', // Origem da animação (esquerda)
      distance: '30px', // Distância de animação
      opacity: 1, // Opacidade final
      easing: 'ease-in-out'// Efeito de easing
    })

    // left
    scrollReveal.reveal(animeSection6.current, {
      duration: 800, // Duração da animação em milissegundos
      origin: 'left', // Origem da animação (esquerda)
      distance: '30px', // Distância de animação
      opacity: 1, // Opacidade final
      easing: 'ease-in-out'// Efeito de easing
    })

    // opacity
    scrollReveal.reveal(animeSection5.current, {
      duration: 2000, // Duração da animação em milissegundos
      opacity: 0, // Opacidade inicial
      beforeReveal: el => {
        el.style.opacity = 1 // Opacidade final (quando a animação começa)
      }
    })

    // opacity
    scrollReveal.reveal(animeOpacity.current, {
      duration: 2000, // Duração da animação em milissegundos
      opacity: 0, // Opacidade inicial
      beforeReveal: el => {
        el.style.opacity = 1 // Opacidade final (quando a animação começa)
      }
    })

    // opacity
    scrollReveal.reveal(animeText.current, {
      duration: 2000, // Duração da animação em milissegundos
      opacity: 0, // Opacidade inicial
      beforeReveal: el => {
        el.style.opacity = 1 // Opacidade final (quando a animação começa)
      }
    })

    // zoom
    scrollReveal.reveal(animeZoom.current, {
      duration: 900, // Duração da animação em milissegundos
      origin: 'bottom', // Origem da animação (vindo de baixo)
      distance: '30px', // Distância de animação
      opacity: 0, // Comece com opacidade 0
      easing: 'ease-in-out', // Efeito de easing
      scale: 1.2, // Escala inicial maior

      // Função para adicionar um efeito de salto (bounce)
      beforeReveal: (el) => {
        el.style.transform = 'scale(1)' // Escala final normal
        el.style.opacity = 1 // Opacidade final 1
      }
    })

    // zoom
    scrollReveal.reveal(animeSection4.current, {
      duration: 900, // Duração da animação em milissegundos
      origin: 'bottom', // Origem da animação (vindo de baixo)
      distance: '30px', // Distância de animação
      opacity: 0, // Comece com opacidade 0
      easing: 'ease-in-out', // Efeito de easing
      scale: 1.2, // Escala inicial maior

      // Função para adicionar um efeito de salto (bounce)
      beforeReveal: (el) => {
        el.style.transform = 'scale(1)' // Escala final normal
        el.style.opacity = 1 // Opacidade final 1
      }
    })
  }, [])

  function WhatsApp (e) {
    e.preventDefault()

    ReactPixel.track('InitiateCheckout', {
      content_name: 'Clique no whatsapp Acessa Aí LandinPage'
      // content_category: 'Categoria do Conteúdo',
      // content_ids: ['ID do Conteúdo'] // Se você tiver IDs de conteúdo
    })

    const phoneNumber = '+5582981129518'

    const message = '✅ Desejo mais informações sobre o sistema Acessa Aí delivery!'

    const messageDados = `✅ Desejo testar o sistema Acessa Aí delivery! \n\n Nome: ${nome} \n\n Email: ${email} \n\n Celular: ${inpCelular} \n\n Nome do delivery: ${delivery}`

    const apiUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`

    const apiUrlDados = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(messageDados)}`

    if (nome && email && inpCelular && delivery) {
      window.location.href = apiUrlDados
    } else {
      window.location.href = apiUrl
    }
  }

  return (
    <div className="landing-page">

      <button className='btnZap' onClick={WhatsApp}>
        <FaWhatsapp size={45} color='#fff' />
      </button>

      <header className="header">
        <img src={logo} alt="Logo" />

        <div className='infoHeader'>
          <h5>home</h5>
          <h5>sobre</h5>
          <h5>contato</h5>
        </div>

        <button className='infoHeaderBtn' onClick={WhatsApp}>conversar</button>
      </header>

      <main className="main-content">

        <div className="banner">
          <div className="banner-text" ref={animeText}>
            <h1>Acessa Ai Delivery</h1>

            <h4>Do whatsapp direto para seu gestor de pedidos</h4>

            <h3 style={{ fontSize: 30 }}>Apenas R$69,90/mês</h3>

            <p>Não cobramos taxas, nem comissão sobre vendas e não há limites de pedidos!</p>

            <div className='containerBannerBtn'>
              <button className='btnBorder' onClick={WhatsApp}>Fale conosco</button>

              <button className='btn' onClick={WhatsApp}>Mais informações</button>
            </div>

          </div>

          <div className="banner-image">
            <img src={celular} ref={animeProduto} />
          </div>
        </div>

        <div className='onda' />

        <div className='section2' ref={animeZoom} >
          <div className='infosSection2' >
            <h1>2k +</h1>
            <h5>Estabelecimentos</h5>
          </div>

          <div className='infosSection2'>
            <h1>60k +</h1>
            <h5>Pedidos por mês</h5>
          </div>

          <div className='infosSection2'>
            <h1>200k +</h1>
            <h5>Acessos</h5>
          </div>
        </div>

        <div className='section3'>
          <h2>Se você está cansado de:</h2>

          <div className='containerReject' ref={animeRight}>
            <div className='reject'>
              <AiFillCloseCircle
                size={30}
                color='205C40'
              />
              <p>Anotar os Pedidos à Mão</p>
            </div>

            <div className='reject'>
              <AiFillCloseCircle
                size={30}
                color='205C40'
              />
              <p>Pedidos errados e Perdidos</p>
            </div>
          </div>

          <div className='containerReject' ref={animeLeft}>
            <div className='reject'>
              <AiFillCloseCircle
                size={30}
                color='205C40'
              />
              <p>Entregas Atrasada</p>
            </div>

            <div className='reject'>
              <AiFillCloseCircle
                size={30}
                color='205C40'
              />
              <p>Clientes Reclamando no seu Whatsapp</p>
            </div>
          </div>

          <div className='containerTextSection3' ref={animeOpacity}>
            <h3>Parabéns, você achou a ferramenta certa!</h3>
            <p style={{ fontSize: 15 }}>Vamos ACABAR de vez com a bagunça no seu Atendimento</p>
          </div>

          <button onClick={WhatsApp} ref={animeZoom}>Quero mais informações</button>
        </div>

        <div className='section4' ref={animeSection5}>
          <div className='containerSection4'>
            <img src={logoBlack} />
            <h5>Conheça nosso sistema</h5>
            <button className='btnVerde' onClick={WhatsApp}>Quero mais informações</button>
          </div>

          <img src={banner} className='bannerImgSection4' />
        </div>

        <div className='section5' ref={animeSection4}>
          <img src={logoBlack} />
          <h5>Como funciona o Acessa Aí</h5>

          <div className='containerVideo'>
            <iframe width="90%" height="315" src="https://www.youtube.com/embed/3-U_8n1BqAs?si=IAq0Faka97i9Ym2O" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            <iframe width="90%" height="315" src="https://www.youtube.com/embed/3-U_8n1BqAs?si=IAq0Faka97i9Ym2O" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

          </div>

          <button className='btnVerde' onClick={WhatsApp}>Quero mais informações</button>
        </div>

        <div className='section6' ref={animeSection6}>
          <h5 style={{ marginBottom: 30 }}>Benefícios</h5>

          <div className='containerBenf'>
            <div className='contBenef'>
              <img src={logoBlack} />
              <h4>Catalogo digital</h4>
              <p>Cadastre todos os seus produtos na sua loja.</p>
            </div>

            <div className='contBenef'>
              <img src={logoBlack} />
              <h4>Painel Administrativo</h4>
              <p>Receba pedidos no WhatsApp e Painel adm ao mesmo tempo, sem interagir com os clientes.</p>
            </div>

            <div className='contBenef'>
              <img src={logoBlack} />
              <h4>Relatório financeiro</h4>
              <p>Tenha todo relatório de vendas diariamente.</p>
            </div>

            <div className='contBenef'>
              <img src={logoBlack} />
              <h4>Sem erros</h4>
              <p>Reduz a zero os erros nos pedidos.</p>
            </div>

            <div className='contBenef'>
              <img src={logoBlack} />
              <h4>Aumenta suas vendas</h4>
              <p>Aumenta o ticket médio de vendas pois oferece adicionais pagos.</p>
            </div>

            <div className='contBenef'>
              <img src={logoBlack} />
              <h4>Mão de obra zero</h4>
              <p>Sem mão de obra extra para anotar pedidos.</p>
            </div>
          </div>
        </div>
        <div className='onda' />

        <div className='section7' ref={animeForm}>
          <h1 style={{ color: '#222' }}>Automatize seu atendimento com o sistema mais completo do país</h1>

          <form>
            <h4>Teste o Acesse Aí sem pagar nada</h4>
            <p>Faça o seu cadastro para testar</p>
            <input type="text" placeholder='Seu nome'
              onChange={(e) => setNome(e.target.value)}
            />
            <input type="text" placeholder='Seu email'
              onChange={(e) => setEmail(e.target.value)}
            />
            <input type="text" placeholder='Celular'
              onChange={(e) => setCelular(e.target.value)}
            />
            <input type="text" placeholder='Nome do seu Delivery'
              onChange={(e) => setDelivery(e.target.value)}
            />

            <button onClick={WhatsApp}>Testar gratis por 7 dias</button>
          </form>

        </div>
      </main>

      <div style={{ marginTop: 60 }}></div>
      <Footer />
    </div>
  )
}
