import './style.css'
import { AiOutlineShoppingCart } from 'react-icons/ai'

export default function CartFlutter ({ press, arrayProducts }) {
  const total = arrayProducts.reduce((acc, item) => {
    return acc + item.quantity
  }, 0)

  return (
    <>
    <div style={{ position: 'fixed', bottom: 30, left: 20, zIndex: 3 }}>

      <div className='btnFlutterSide' onClick={press}>
        <div className={total ? 'QtItemCarFlutter' : 'QtItemCarFlutterClose'}>
          <h1>{total}</h1>
        </div>
        <AiOutlineShoppingCart size={24} color='white'/>
      </div>

    </div>
    </>
  )
}
