import React, { useState } from 'react'
import './style.css'

export default function MyPedidos ({ nome, data, items, total, status, pagamento, EmpresaTelefone }) {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  function handlleCancel () {
    const phoneNumber = EmpresaTelefone

    const message = `❌ *Desejo cancelar o meu pedido* \n\nCliente: ${nome}\n\nData: ${data}\n\nPedido: ${items.map((item) => ' ' + item.quantity + 'x' + ' ' + item.product.nome
    )}
`

    const apiUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`

    window.location.href = apiUrl
  }

  return (
    <div className={`containerComMeusPedidos ${isExpanded ? 'expanded' : ''}`}>

      <div>
        <div onClick={toggleAccordion}>
          <div>
            <h4
              style={{ fontSize: 14, fontWeight: 300, marginBottom: 2 }}>{data} - {nome}
            </h4>

            <p
              style={{ fontSize: 12, marginBottom: 16, color: status !== 'Fila de espera' ? 'green' : 'red' }}
            >
              Status: {status}
            </p>
          </div>

          {items.map((item) => {
            return (
              <p key={item.id}>{item.quantity}x - {item.product.nome}</p>
            )
          })}

          <hr style={{ margin: 4 }} />
          <p>Forma de pagamento: {pagamento}</p>
          <h5 style={{ marginTop: 8 }}>Total: R${parseFloat(total).toFixed(2)}</h5>

        </div>
          {status === 'Fila de espera' &&
          <div style={{ width: '100%', marginTop: 8 }}>
           <button onClick={handlleCancel} className='btnMyPedidos'>Cancelar pedido</button>
          </div>
          }
      </div>
    </div>
  )
}
